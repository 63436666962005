// WebHQ ***
// @import url('http://fonts.googleapis.com/css?family=Lato:400,700');

// $font-stack: 'Lato',Helvetica,Arial,Lucida,sans-serif;

// $primary: #ffffff;
// $secondary: #326db5;
// $tertiary: #232323;

// $body-color: #232323;
// $h1-color: $primary;
// $h2-color: #ffffff;
// $header-color: #ffffff;
// $p-color: #ffffff;
// ENDWEBHQ Styles **************************

// MEPS ***
@import url('https://fonts.googleapis.com/css?family=Quicksand:400,700');

$font-stack: 'Quicksand',Helvetica,Arial,Lucida,sans-serif;

$primary: #92288e;
$secondary: #004154;
$tertiary: #004053;

$body-color: #ffffff;
$h1-color: white;
$h2-color: $secondary;
$header-color: $secondary;
$p-color: $secondary;
// END MEPS Styles **************************

// PelviPower ***
// @import url('http://fonts.googleapis.com/css?family=Roboto:400,700');

// $font-stack: 'Roboto',sans-serif;

// $primary: #000000;
// $secondary: #749B26;
// $tertiary: #003243;

// $body-color: #ffffff;
// $h1-color: white;
// $h2-color: $secondary;
// $header-color: $primary;
// $p-color: $primary;
// END PelviPower Styles **************************

// MUMMYMOT STYLES ***
// @import url('http://fonts.googleapis.com/css?family=Quicksand:400,700');

// $font-stack: 'Quicksand',Helvetica,Arial,Lucida,sans-serif;

// $primary: #ef21a4;
// $secondary: #0aa499;
// $tertiary: #FDB3DE;

// $body-color: #ffffff;
// $h1-color: $primary;
// $h2-color: $primary;
// $header-color: $secondary;
// $p-color: #000000;

// END MUMMYMOT STYLES **************************

.site-header {
  background-color: $tertiary !important;
}

.btn-outline-primary {
  color: $primary !important;
  border-color: $primary !important;
}

.btn-outline-primary:hover, .btn-outline-primary:focus, .btn-outline-primary:active, .btn-outline-primary.active, .open>.dropdown-toggle.btn-outline-primary {
  color: #fff !important;
  background-color: $secondary !important;
  border-color: #92288e !important; /*set the color you want here*/
}

.btn-outline-secondary {
  color: $secondary !important;
  border-color: $secondary !important;
}

h1 {
  color: $h1-color;
}

p {
  color: $p-color;
}

// html {
//   font-size: 20px;
// }

// h1 {
//   font-size: 2.5rem !important;
// }

// h2 {
//   font-size: 1.5rem !important;
// }

.question {
  font-size: 1.3rem !important;
}

.styled-form {
  max-width: 500px !important;
  color: $header-color !important;
}

// End overrides

.centered-container {
  max-width: 700px;
}

$blue: #31708E;
$danger: #dd0000; 
$success: lime;
$warning: orange;
$info: aqua;

$white: #f7f9fb !default;
$gray-100: #F9FBFD !default;
$gray-200: #F1F4F8 !default;
$gray-300: #D9E2EF !default;
$gray-400: #C6D3E6 !default;
$gray-500: #ABBCD5 !default;
$gray-600: #869AB8 !default;
$gray-700: #506690 !default;
$gray-800: #384C74 !default;
$gray-900: #1B2A4E !default;
$black: #161C2D !default;
$green-inverse: #d8e8e0 !default;

body  {
  background-color: $body-color !important;
}

h2 {
  color: $h2-color;
}

h3, h4, h5 {
  color: $header-color;
}

// Variables
//
// Variables should follow the `$component-state-property-size` formula for
// consistent naming. Ex: $nav-link-disabled-color and $modal-content-box-shadow-xs.

//
// Bootstrap overrides ===================================
//

// Color system

$white: #FFFFFF !default;
$gray-100: #F9FBFD !default;
$gray-200: #F1F4F8 !default;
$gray-300: #D9E2EF !default;
$gray-400: #C6D3E6 !default;
$gray-500: #ABBCD5 !default;
$gray-600: #869AB8 !default;
$gray-700: #506690 !default;
$gray-800: #384C74 !default;
$gray-900: #1B2A4E !default;
$black: #161C2D !default;

$primary-desat: #6C8AEC !default; // NEW
$success: #42BA96 !default;
$info: #7C69EF !default;
$warning: #FAD776 !default;
$danger: #DF4759 !default;
$light: $gray-100 !default;
$dark: $gray-900 !default;

/* beautify ignore:start */
$theme-colors: (
  "primary": $primary,
  "secondary": $secondary,
  "blue": $blue,
  "success": $success,
  "info": $info,
  "warning": $warning,
  "danger": $danger,
  "light": $light,
  "dark": $dark,
  "primary-desat": $primary-desat, // NEW
  "black": $black, // NEW
  "white": $white, // NEW
  "green-inverse": $green-inverse
);
/* beautify ignore:end */

// The contrast ratio to reach against white, to determine if color changes from "light" to "dark". Acceptable values for WCAG 2.0 are 3, 4.5 and 7.
// See https://www.w3.org/TR/WCAG20/#visual-audio-contrast-contrast
$min-contrast-ratio: 1.75 !default;

// Customize the light and dark text colors for use in our YIQ color contrast function.
$color-contrast-dark: $gray-900 !default;
$color-contrast-light: $white !default;

// Spacing
//
// Control the default styling of most Bootstrap elements by modifying these
// variables. Mostly focused on spacing.
// You can add more entries to the $spacers map, should you need more variation.

$spacer: 1rem !default;
$spacers: () !default;

/* beautify ignore:start */
$spacers: map-merge(
  (
    0: 0,
    1: ($spacer * .25), // 4px
    2: ($spacer * .5), // 8px
    3: ($spacer * .75), // 12px
    4: $spacer, // 16px
    5: ($spacer * 1.5), // 24px
    6: ($spacer * 2), // 32px
    7: ($spacer * 2.5), // 40px
    8: ($spacer * 3), // 48px
    9: ($spacer * 4), // 64px
    10: ($spacer * 5), // 80px
    11: ($spacer * 6), // 96px
    12: ($spacer * 8), // 128px
    13: ($spacer * 10), // 160px 
    14: ($spacer * 12), // 192px
    15: ($spacer * 16), // 256px                                                     
    16: ($spacer * 25), // 400px
  ),
  $spacers
);
/* beautify ignore:end */


/* WebHQ Styles */

.range-slider {
  max-width: 300px;
}

.full-width-form {
  max-width: 500px;
}

#branding img {
    max-width: 380px;
}

.nav-link {
    font-size: 1.3rem !important;
    color: black !important;
    font-weight: 500 !important;
    margin-right: 10px;
    margin-top: 5px;
}

.site-header {
    // min-height: 80px;
    // background: $body-bg-alt;
}

.navbar-brand {
    font-size: 30px;
    font-weight: 600;
    color: $primary;
    margin-top: 4px;
}

.product-image {
  max-height: 200px;
}

#trackersackBags {
  margin-bottom: -50px;
}

#trackersackBagWrapper {
  overflow: hidden;
}

.ctaSection {
  z-index: 10;
}

$border-width: 1px !default;
$border-color: $gray-200 !default;

$border-radius-sm: .25rem !default;
$border-radius: 0.375rem !default;
$border-radius-lg: 0.5rem !default;
$border-radius-xl: 1rem !default; // NEW
$border-radius-pill: 50rem !default;

// Cards

$card-spacer-y: 2rem !default;
$card-spacer-x: 2rem !default;
$card-title-spacer-y: 1.5rem !default;
$card-border-width: 0px !default;
$card-border-color: $border-color !default;
$card-cap-padding-y: 1.5rem !default;

$card-meta-spacer-y: 1.5rem !default;

$card-row-spacer-y: 4rem !default;
$card-row-spacer-x: 2.5rem !default;

$card-border-radius: 1em !default;
$card-inner-border-radius: 1em !default;

$blockquote-font-size: 1.25rem !default;
$blockquote-footer-color: $gray-600 !default;
$blockquote-footer-font-size: 1rem !default;

// Grid columns
//
// Set the number of columns and specify the width of the gutters.

$grid-gutter-width: 2.5rem !default;

//
// card.scss
// Extended from Bootstrap
//

// Card resets

.card {
    position: relative;
    width: 100%;
  }
  
  .card-header {
    background-color: transparent;
    border-bottom: $border-width solid $border-color;
  }
  
  .card-body {
    display: block;
    flex-grow: 0;
  }
  
  .card-footer {
    background-color: unset;
  }
  
  .card-body, .card-footer, .card-meta, .card-img, .card-img-top, .card-img-start, .card-img-end {
    position: relative;
    min-height: 1px;
  }
  
  
  // Card action
  
  a.card, a.card-body, a.card-footer, a.card-meta {
    color: inherit;
  
    &:hover {
      text-decoration: none;
    }
  }
  
  
  // Card flush
  
  .card-flush {
    background-color: unset;
  }
  
  .card-flush > *:not(.card-btn) {
    padding-left: 0;
    padding-right: 0;
  }
  
  
  // Card border
  
  .card-border {
    position: relative;
  }
  
  .card-border::after {
    content: "";
    position: absolute;
    top: 0;
    right: 0;
    width: 100%;
    border-top-width: $border-width * 2;
    border-bottom-width: calc(#{$card-border-radius} - #{$border-width * 2});
    border-top-style: solid;
    border-bottom-style: solid;
    border-top-color: inherit;
    border-bottom-color: transparent;
    border-top-left-radius: $card-border-radius;
    border-top-right-radius: $card-border-radius;
  }
  
  .card-border-lg::after {
    border-top-width: $border-width * 3;
    border-bottom-width: calc(#{$card-border-radius} - #{$border-width * 3});
  }
  
  .card-border-xl::after {
    border-top-width: $border-width * 4;
    border-bottom-width: calc(#{$card-border-radius} - #{$border-width * 3});
  }
  
  
  // Card row
  
//   .card-row {
  
//     .card-body {
  
//       @include media-breakpoint-up(md) {
//         padding: $card-row-spacer-y $card-row-spacer-x;
//       }
//     }
  
//     .card-meta {
  
//       @include media-breakpoint-up(md) {
//         padding-left: $card-row-spacer-x;
//         padding-right: $card-row-spacer-x;
//       }
//     }
//   }
  
  
  // Card image
  
//   .card-img-end {
//     border-radius: $border-radius $border-radius 0 0;
  
//     @include media-breakpoint-up(md) {
//       border-radius: 0 $border-radius $border-radius 0;
//     }
//   }
  
//   .card-img-start {
//     border-radius: $border-radius $border-radius 0 0;
  
//     @include media-breakpoint-up(md) {
//       border-radius: $border-radius 0 0 $border-radius;
//     }
//   }
  
  
  // Card image slider
  
//   .card-img-slider {
  
//     @include media-breakpoint-up(md) {
//       height: 100%;
//       width: 100%;
  
//       * {
//         height: inherit !important;
//         width: inherit !important;
//       }
//     }
//   }
  
  
  // Card image overlay
  
  .card-img-overlay {
    display: flex;
    flex-direction: column;
    padding: $card-spacer-x;
  }
  
  .card-img-overlay .card-body {
    margin: -$card-spacer-x;
    margin-top: auto;
    border-bottom-right-radius: $card-inner-border-radius;
    border-bottom-left-radius: $card-inner-border-radius;
  }
  
  
  .card-img-overlay-hover {
    overflow: hidden;
  }
  
  .card-img-overlay-hover .card-body {
    transform: translateY(110%);
    transition: all .3s ease;
  }
  
  .card-img-overlay-hover:hover .card-body {
    transform: translateY(0);
  }
  
  
  
  // Card group
  
//   .card-group {
  
//     @include media-breakpoint-only(sm) {
//       flex-direction: column !important;
  
//       > .card {
//         flex: auto;
//       }
//     }
//   }
  
  
  // Card meta
  
  .card-meta {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    padding: 0 $card-spacer-x $card-meta-spacer-y;
  }
  
  .card-meta-divider {
    width: 100%;
    margin: 0 0 $card-meta-spacer-y;
  }
  
  
  // Card button
  
  .card-btn:last-child {
    border-top-right-radius: 0;
    border-top-left-radius: 0;
  }
  
  
  // Zoom
  
  .card-zoom {
    overflow: hidden !important;
    border-radius: inherit;
  }
  
  .card-zoom > [class*="card-img"] {
    transition: all .3s ease;
    transform-origin: center center;
  }
  
  .card:hover > .card-zoom > [class*="card-img"] {
    transform: scale(1.1);
  }
  
  
  // Card list
  
  .card-list .list-link {
    position: relative;
  }
  
  .card-list .list-link::before {
    content: "";
    position: absolute;
    top: 0;
    bottom: 0;
    left: -$card-spacer-x;
    border-right: $border-width * 2 solid $primary;
    display: none;
  }
  
  .card-list .list-link:hover {
    color: $body-color !important;
  }
  
  .card-list .active .list-link {
    color: $body-color !important;
  }
  
  .card-list .active .list-link::before {
    display: block;
  }
  
  
  // Card bleed
  
//   @include media-breakpoint-down(md) {
  
//     .card-bleed {
//       width: auto;
//       min-width: 100%;
//       margin-right: -$grid-gutter-width * .5;
//       margin-left: -$grid-gutter-width * .5;
//       border-radius: 0;
//     }
  
//     .card-bleed .card-footer,
//     .card-bleed .card-header,
//     .card-bleed .card-body {
//       padding-right: $grid-gutter-width * .5;
//       padding-left: $grid-gutter-width * .5;
//       border-radius: 0;
//     }
  
//     .card-bleed .card-list .list-link::before {
//       left: -$grid-gutter-width * .5;
//     }
//   }
  
// Blockquote

.blockquote-img {
    max-height: 2.5rem;
    max-width: 100%;
    width: auto;
  }
  
  .blockquote-footer {
    margin-top: 0;
  
    &::before {
      display: none;
    }
  }

//
// grid.scsss
// Extended from Bootstrap
//

[class^="container"] {
    position: relative;
    z-index: 1;
    padding-right: $grid-gutter-width * .5;
    padding-left: $grid-gutter-width * .5;
  }
  
  [class^="col"] {
    position: relative;
  }
  


// Import Bootstrap and its default variables
@import '~bootstrap/scss/bootstrap.scss';
